<template>
  <v-app>
     <v-banner single-line class="banner">
       <v-img
           src="https://h5.ophyer.cn/official_website/banner/dig-banner.png"
           height="100%"
           :transition="false"
           class="d-flex align-center"

       >
         <template v-slot:placeholder>
           <v-row class="fill-height ma-0" align="center" justify="center">
             <v-progress-circular
                 indeterminate
                 size="80"
                 color="red lighten-5"
             ></v-progress-circular>
           </v-row>
         </template>
          <v-container color="transparent" class="d-flex  max-width-1200">
           <v-card color="transparent" flat >
             <div class="d-flex flex-no-wrap justify-space-between ml-4">
               <div>
                 <v-card-title class="white--text h5-banner-title font-size-h5banner-title" >
                   数字人
                 </v-card-title>
               </div>
             </div>
           </v-card>
         </v-container>
       </v-img>
     </v-banner>
    <!-- 核心价值(竖向排列) -->
    <v-card class="px-4 py-6 mt-2" flat>
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">核心价值</v-card-title>
      <div class="h5-public-title">CORE VALUE</div>
      <div class="card mt-4">
        <v-row v-for="(row,rowIndex) in data.wrap2.list" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card" min-height="90" width="100%" flat>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-1 font-size-16 line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text v-html="row.content" class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.content}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>

    <!-- 方案构成 -->
    <v-card class="px-4 py-6 h5model_list3" flat>
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">方案构成</v-card-title>
      <div class="h5-public-title">SCHEME COMPOSITION</div>
      <div class="mt-3">
        <v-row>
          <v-col v-for="(v,rowIndex) in modelList3" :key="rowIndex" cols="6" style="padding: 10px">
            <v-card class="pa-0 d-flex h5-card" min-height="90" width="100%" flat>
              <div class="model_item">
                <img class="item_img" :src='v.icon' alt/>
                <div class="item_info">
                  <v-card-title class="justify-center reset-title font-size-16">{{ v.title }}</v-card-title>
                  <v-card-text class="reset-text">{{v.text}}</v-card-text>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 应用场景 -->
    <v-card class="justify-center wap3" :flat="true" style="padding: 24px 0 0px;background: #FBFBFB;">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">应用场景</v-card-title>
      <div class="h5-public-title">APPLICATION SCENARIO</div>
      <div class="con">
        <v-row>
          <v-col
            v-for="(n,index) in cards"
            :key="index"
            class="d-flex child-flex"
            cols="12"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div class="tit">
                <div class="center">
                  <p class="p1">{{ n.tit }}</p> 
                  <p class="p2">{{ n.content }}</p>     
                </div>         
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';
export default {
  name: 'h5digitalMan',
  components: {
  },
  data() {
    return {
      data: contentData.digitalMan,
      imgLoad: false,
      tab: 0,
        //展示终端
      cards: [
        { id:1, src: 'https://h5.ophyer.cn/official_website/other/dig-changjing-01.png', tit:'金融客服'},
        { id:4,  src: 'https://h5.ophyer.cn/official_website/other/dig-changjing-02.png', tit:'车载助理'},
        { id:5,  src: 'https://h5.ophyer.cn/official_website/other/dig-changjing-03.png', tit:'文旅展厅'},
        // { id:1, src: 'https://h5.ophyer.cn/official_website/other/VRmb1.png', tit:'金融客服'},
        // { id:4,  src: 'https://h5.ophyer.cn/official_website/other/VRmb4.png', tit:'车载助理'},
        // { id:5,  src: 'https://h5.ophyer.cn/official_website/other/learn1-1.jpg', tit:'文旅展厅'},
      ],
      modelList3: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/dig-icon-01.png",
          title: "形象风格定制",
          text: "依据需求定制专属虚拟形象，涵盖动物、卡通、真人风格，满足各类应用场景"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/dig-icon-02.png",
          title: "口唇/情绪驱动",
          text: "根据语音内容实时预测唇形变化，实现语音唇形同步，驱动虚拟形象多模态交互"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/dig-icon-03.png",
          title: "多系统终端支持",
          text: "iOS/Android/Windows/Linux终端系统支持，覆盖主流屏幕设备"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/dig-icon-04.png",
          title: "主流语音助手兼容",
          text: "快速前端实时渲染后端视频流实时展示实时全场景声音视觉与触屏交互"
        }
      ],
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    handleTabBtn: function (type){
      const maxTab = this.data.wrap1.items.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  },
  mounted(){
    this.bannerLoad = true;
  }
}
</script>

<style lang="scss" scoped>
.warps {
  margin: 0 auto;
  text-align: center;
}
.warp1 {
  width: 100%;
  .model_list1 {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .model_item{
      width: 300px;
      background: #FFFFFF;
      border-radius: 16px;
      padding: 15px 12px;
      .item_img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .item_info{
        margin-top: 10px;
      }
    }
  }
}

.wap3{
  width: 1200px;
  margin: 0 auto;
  .public-title {
    margin-top: 57px !important;
  }
.con {
    margin: 25px;
    .box-img {
      width: 300px;
      height: 210px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      .tit{
         background-color: rgba(25, 26, 27, 0.5);
         width: 100%;
         height: 100%;
         position: relative;
         color: #fff;
         font-size: 24px;
         display: flex;
         justify-content: center;
         align-items: center;
      }
      .center{
         position: absolute;
         padding: 12px;
         text-align: center;
         .p1{
           font-size: 18px;
           font-family: SourceHanSansCN-Bold, SourceHanSansCN;
           font-weight: bold;
           color: #FFFFFF;
           line-height: 27px;
         }
         .p2{
           font-size: 14px;
           font-family: SourceHanSansCN-Regular, SourceHanSansCN;
           font-weight: 400;
           color: #FFFFFF;
           line-height: 16px;
         }
      }
      // &:hover {
      //   transform: scale(1.1);
      // }
    }
  }
}
.h5model_list3{
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    min-height: 184px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 0 10px;
    text-align: center;
    .item_img{
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 8px;
    }
  }
}
</style>
